/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import {
  Box,
  Container,
  Stack,
  Checkbox,
  Image,
  Flex,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import { BsDownload } from 'react-icons/bs';
import SEO from '../components/seo';
import Layout from '../components/layout';
// import CustomButton from '../components/custom-button';
import '../styles/downloads.scss';

const Downloads = (props) => {
  const { data } = props;
  const [postsList, setPostsList] = useState(data.allWpDownload.edges);
  const [tags, setTags] = useState([]);
  const handleFilters = (event, tag) => {
    if (event.target.checked) {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
      setTags(tags);
    } else {
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      setTags(tags);
    }

    const filterResult = [];

    data.allWpDownload.edges.filter((node) => {
      if (tags.length === 0) {
        filterResult.push(node);
      } else if (tags.length === 1) {
        const foundPost = node.node.downloadCategories.nodes.find(
          (post) => post.slug === tags[0]
        );
        if (foundPost) {
          filterResult.push(node);
        }
      } else {
        const result = [];
        tags.forEach((subtag) => {
          const foundPost = node.node.downloadCategories.nodes.find(
            (post) => post.slug === subtag
          );
          if (foundPost) {
            result.push(node);
          }
        });
        const toFindDuplicates = (a) =>
          a.filter((item, index) => a.indexOf(item) !== index);
        const duplicateElements = toFindDuplicates(result);
        if (duplicateElements.length !== 0) {
          duplicateElements.forEach((dup) => {
            if (dup.node.downloadCategories.nodes.length >= tags.length) {
              filterResult.push(dup);
            }
          });
        }
      }
    });
    const uniqueResult = [...new Set(filterResult)];

    setPostsList(uniqueResult);
  };
  return (
    <Layout>
      <SEO
        title="Downloads, Presentations, Data Sheets About Crystal Green Fertilizer | Ostara"
        description="Get the facts about Crystal Green fertilizers and our nutrient recovery technology with these fact sheets, presentations and other downloads."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Downloads"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24} marginTop={100}>
              <Stack maxW="50rem" spacing={6}>
                <h1>Downloads</h1>
                <p>
                  Get the facts with data sheets, product sheets and
                  specifications for our Crystal Green fertilizers and nutrient
                  recovery technology.
                </p>
              </Stack>
              <div className="downloads-filter">
                <h3>Filter by:</h3>
                {data.allWpDownloadCategory.edges.map((tag) => (
                  <Checkbox
                    type="checkbox"
                    key={tag.node.slug}
                    className="custom-checkbox"
                    onChange={(event) => handleFilters(event, tag.node.slug)}
                  >
                    <span>✓</span>
                    {tag.node.name}
                  </Checkbox>
                ))}
              </div>
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="downloads-body-container">
          <Container className="downloads-body-container--box2" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={24}>
              {postsList && postsList.length > 0
                ? postsList.map((item) => (
                    <div className="download-item" key={item.node.id}>
                      <Image
                        src={`${item.node.featuredImage.node.sourceUrl}`}
                        alt="donwload img"
                        className="download-thumbnail"
                      />
                      <div className="download-title">
                        <a
                          href={item.node.download.file.mediaItemUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h5>{item.node.title}</h5>
                        </a>
                        <div className="download-tag-wrapper">
                          <div className="download-tag">
                            {item.node.downloadCategories.nodes.map((tag) => (
                              <p key={tag.id}>{tag.name}</p>
                            ))}
                          </div>
                          <a
                            href={item.node.download.file.mediaItemUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon boxSize={6} color="#044606" as={BsDownload} />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </SimpleGrid>
          </Container>
          <Flex alignItems="center" justifyContent="center">
            {/* <CustomButton maxW={230} to="/404" marginTop={10}>
              LOAD MORE
            </CustomButton> */}
          </Flex>
        </Box>
      </main>
    </Layout>
  );
};

export default Downloads;
export const postQuery = graphql`
  query {
    allWpDownloadCategory {
      edges {
        node {
          name
          slug
        }
      }
    }

    allWpDownload {
      edges {
        node {
          id
          title
          download {
            file {
              mediaItemUrl
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          slug
          downloadCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;
